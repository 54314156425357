import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './Topbar.module.css';

const LanguageMenuIcon = props => {
    const { className, rootClassName } = props;
    const classes = classNames(rootClassName || css.rootMenuIcon, className);

    return (
        <svg className={classes} viewBox="0 0 1024 1024" version="1.1"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M507.9 909.1c33.2 0 66.4-4.1 99.6-12.4-4.1-8.3-12.4-12.4-24.9-8.3 12.4-58.1 20.7-87.1 45.6-112 16.6-12.4 24.9-33.2 12.4-49.8-8.3-12.4-24.9-20.7-37.3-16.6-24.9 4.1-8.3-29-29-33.2-20.7-4.1-49.8-45.6-78.8-58.1-16.6-8.3-33.2-29-58.1-29-20.7 0-53.9 20.7-53.9 4.1 0-49.8-4.1-83-4.1-99.6 0-12.4-8.3-4.1 24.9-4.1 16.6 0 8.3-33.2 24.9-33.2 16.6 0 53.9 16.6 62.2 8.3 8.3-4.1 66.4 149.4 66.4 24.9 0-16.6-8.3-41.5 0-53.9 20.7-33.2 41.5-70.5 58.1-107.9-16.6-4.1-37.3-4.1-53.9 0-8.3 4.1 4.1 16.6-8.3 16.6-41.5 8.3-78.8-12.4-66.4-33.2 12.4-20.7 62.2-8.3 66.4-49.8 4.1-24.9 4.1-49.8 4.1-70.5 53.9 8.3 49.8-62.2-20.7-78.8h-20.7c-190.9 0-352.7 132.8-390.1 319.5 16.6 16.6 45.6 16.6 58.1-4.1-4.1-4.1-4.1-4.1 0-8.3 16.6 12.4 41.5 12.4 41.5 33.2 0 70.5 4.1 145.2 66.4 145.2 24.9 8.3 41.5 29 49.8 53.9 4.1 16.6 29 0 49.8 0 12.4 0 0 20.7 0 62.2s95.4 112 95.4 112c0 24.9 0 45.6 4.1 70.5-12.4 0-29 0-41.5 4.1 20.7 8.3 37.3 8.3 58 8.3z m373.5-531.2s-4.1 0-4.1-4.1c-24.9-66.4-87.1-16.6-66.4 37.3-116.2 87.1-83 149.4-45.6 182.6 20.7 20.7 37.3 41.5 49.8 66.4-12.4 33.2 37.3 24.9 70.5-24.9 16.6-41.5 20.7-87.1 20.7-132.8v-37.3c0-8.3 0-16.6-4.1-20.7-4.2-16.7-12.5-41.6-20.8-66.5z" />
            <path d="M512.1 946.5c-240.7 0-435.7-195-435.7-435.7S271.4 75 512.1 75s435.7 195 435.7 435.7-195 435.8-435.7 435.8z m0-830c-215.8 0-394.2 178.4-394.2 394.2S296.3 905 512.1 905s394.2-178.4 394.2-394.2-178.4-394.3-394.2-394.3z" />
        </svg>
    );
};

const { string } = PropTypes;

LanguageMenuIcon.defaultProps = {
    className: null,
    rootClassName: null,
};

LanguageMenuIcon.propTypes = {
    className: string,
    rootClassName: string,
};

export default LanguageMenuIcon;
